export default [
  {
    header: 'Report',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    header: 'Transaction',
    resource: 'Transaction',
    action: 'read',
  },
  {
    title: 'All transactions',
    route: 'transaction-v2',
    resource: 'Transaction',
    action: 'read',
    icon: 'ListIcon',
  },
  {
    title: 'Withdrawal (V1)',
    route: 'withdrawal',
    resource: 'Withdrawal',
    action: 'read',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Order',
    route: 'order-v2',
    resource: 'Order',
    action: 'read',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Donation',
    route: 'donation-v2',
    resource: 'Donation',
    action: 'read',
    icon: 'GiftIcon',
  },
  {
    title: 'Subscription',
    route: 'subscription-v2',
    resource: 'Subscription',
    action: 'read',
    icon: 'BellIcon',
  },
  {
    title: 'Creator plan',
    route: 'creator-plan-v2',
    resource: 'CreatorPlan',
    action: 'read',
    icon: 'AwardIcon',
  },
  {
    title: 'Topup',
    route: 'topup',
    resource: 'Topup',
    action: 'read',
    icon: 'ArrowUpIcon',
  },
  {
    title: 'Pay Per View',
    route: 'pay-per-view',
    resource: 'PayPerView',
    action: 'read',
    icon: 'EyeIcon',
  },
  {
    header: 'Transaction (Legacy)',
    resource: 'Transaction',
    action: 'read',
  },
  {
    title: 'Transaction (V1)',
    icon: 'ListIcon',
    children: [
      {
        title: 'All transactions',
        route: 'transaction',
        resource: 'Transaction',
        action: 'read',
      },
      {
        title: 'Order',
        route: 'order',
        resource: 'Order',
        action: 'read',
      },
      {
        title: 'Donation',
        route: 'donation',
        resource: 'Donation',
        action: 'read',
      },
      {
        title: 'Subscription',
        route: 'subscription',
        resource: 'Subscription',
        action: 'read',
      },
      {
        title: 'Creator plan',
        route: 'creator-plan',
        resource: 'CreatorPlan',
        action: 'read',
      },
    ],
  },
  {
    title: 'Discrepancy',
    route: 'transaction-discrepancy',
    resource: 'Transaction',
    action: 'read',
    icon: 'AlertCircleIcon',
  },
  {
    header: 'Promotion',
    resource: 'Voucher',
    action: 'read',
  },
  {
    title: 'Voucher',
    route: 'voucher',
    resource: 'Voucher',
    action: 'read',
    icon: 'TagIcon',
  },
  {
    title: 'Banner',
    route: 'banner',
    resource: 'Banner',
    action: 'read',
    icon: 'ImageIcon',
  },
  {
    title: 'Event',
    route: 'event',
    resource: 'Event',
    action: 'read',
    icon: 'CalendarIcon',
  },
  // {
  //   title: 'Show Reels',
  //   route: 'show-reel',
  //   resource: 'ShowReel',
  //   action: 'read',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Gamer Spotlight',
  //   route: 'gamer-spotlight',
  //   resource: 'GamerSpotlight',
  //   action: 'read',
  //   icon: 'CalendarIcon',
  // },
  {
    header: 'User data',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'User',
    route: 'user',
    icon: 'UserIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Manual Profile Reviewing',
    route: 'manual-profile-review',
    resource: 'ManualProfileReview',
    action: 'read',
    icon: 'UserIcon',
  },
  {
    title: 'Service',
    route: 'service',
    resource: 'Service',
    action: 'read',
    icon: 'CoffeeIcon',
  },
  {
    title: 'Wallet',
    route: 'wallet',
    resource: 'Wallet',
    action: 'read',
    icon: 'PocketIcon',
  },
  // {
  //   title: 'Transfer',
  //   route: 'transfer-wallet',
  //   resource: 'TransferWallet',
  //   action: 'read',
  //   icon: 'RepeatIcon',
  // },
  {
    title: 'Finder',
    route: 'finder',
    resource: 'UsernameTracker',
    action: 'read',
    icon: 'SearchIcon',
  },
  {
    title: 'Community',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Post',
        route: 'post',
        resource: 'Post',
        action: 'read',
      },
      {
        title: 'Review',
        route: 'review',
        resource: 'Review',
        action: 'read',
      },
      {
        title: 'Referral',
        route: 'referral',
        resource: 'Referral',
        action: 'read',
      },
      {
        title: 'Report',
        route: 'report',
        resource: 'Report',
        action: 'read',
      },
    ],
  },
  {
    header: 'Master data',
    resource: 'Game',
    action: 'read',
  },
  {
    title: 'Credit Package',
    route: 'credit-package',
    resource: 'CreditPackage',
    action: 'read',
    icon: 'StopCircleIcon',
  },
  {
    title: 'Catalog',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Game',
        route: 'Game',
        resource: 'Game',
        action: 'read',
      },
      {
        title: 'Category',
        route: 'category',
        resource: 'Category',
        action: 'read',
      },
      {
        title: 'Multiple Section',
        route: 'multiple-section',
        resource: 'MultipleSection',
        action: 'read',
      },
      {
        title: 'Multiple Game',
        route: 'multiple-game',
        resource: 'MultipleGame',
        action: 'read',
      },
      {
        title: 'Highlight',
        route: 'highlight',
        resource: 'Highlight',
        action: 'read',
      },
      {
        title: 'Interest',
        route: 'interest',
        resource: 'Interest',
        action: 'read',
      },
      {
        title: 'Profanity',
        route: 'profanity',
        resource: 'Profanity',
        action: 'read',
      },
    ],
  },
  {
    header: 'Settings',
    resource: 'Cms',
    action: 'read',
  },
  {
    title: 'CMS',
    route: 'cms',
    resource: 'Cms',
    action: 'read',
    icon: 'PocketIcon',
  },
  {
    title: 'Exchange Rate',
    route: 'exchange-rate',
    resource: 'ExchangeRate',
    action: 'read',
    icon: 'RefreshCcwIcon',
  },
  {
    title: 'Processing Fee',
    route: 'processing-fee',
    resource: 'ProcessingFee',
    action: 'read',
    icon: 'PercentIcon',
  },
  {
    title: 'App Setting',
    route: 'app-setting',
    resource: 'AppSetting',
    action: 'read',
    icon: 'SettingsIcon',
  },
  {
    title: 'Activity Log',
    route: 'activity-log',
    resource: 'ActivityLog',
    action: 'read',
    icon: 'MonitorIcon',
  },
]
