<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder text-capitalize mb-0">
              <span v-if="fullName">{{ fullName }}</span>
              <i
                v-else
                class="text-muted font-weight-bolder"
              >No Name</i>
            </p>
            <span class="user-status text-capitalize">{{ roles }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="defaultImage"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="editUserRoute"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Edit Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'export-result' }"
        >
          <feather-icon
            size="16"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>Exported Data</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'edit-password' }"
        >
          <feather-icon
            size="16"
            icon="KeyIcon"
            class="mr-50"
          />
          <span>Edit Password</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          class="d-none"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="confirmLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Refund confirmation modal -->
    <b-modal
      v-model="logoutModalVisible"
      title="Logout"
      ok-title="Yes, sure"
      cancel-variant="white"
      @ok="logout"
    >
      Are you sure want to logout?
    </b-modal>
  </div>
</template>

<script>
// TODO: fix all no-cycle
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      logoutModalVisible: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    fullName() {
      if (!this.userData) {
        return ''
      }

      return [this.userData.firstName, this.userData.lastName].join(' ').trim()
    },
    roles() {
      if (!this.userData) {
        return ''
      }

      return this.userData.roles.filter(item => item !== 'customer').join(', ')
    },
    defaultImage() {
      if (!this.userData) {
        return null
      }

      return this.$options.filters.thumbnail(this.userData.avatar, 40)
    },
    editUserRoute() {
      if (!this.userData) {
        return '#'
      }

      return `/user/${this.userData.id}/edit`
    },
  },
  methods: {
    confirmLogout() {
      this.logoutModalVisible = true
    },
    logout() {
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push(getHomeRouteForLoggedInUser())
    },
  },
}
</script>
